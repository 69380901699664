import { ReactNode } from "react";
import ReactSelect, {
  Props as ReactSelectProps,
  GroupBase,
} from "react-select";
import AsyncSelect, { AsyncProps } from "react-select/async";
import IconComponent from "shared/IconComponent";
import { IconName } from "shared/IconComponent/IconComponent";
import Loader from "shared/Loader";
import { LoaderSizes } from "shared/Loader/Loader";

export type TOption = {
  value: string | number;
  label: string | number;
  isDisabled?: boolean;
  icon?: () => ReactNode;
};

type TSelectProps = ReactSelectProps<TOption> &
  AsyncProps<TOption, false, GroupBase<TOption>> & {
    label?: string;
    isAsync?: boolean;
    isNaked?: boolean;
    iconName?: IconName;
  };

function Select({
  label,
  isNaked,
  isAsync,
  className,
  iconName = IconName.DownArrow,
  ...otherProps
}: TSelectProps) {
  const Component = isAsync ? AsyncSelect : ReactSelect;

  return (
    <div className={`a-select ${isNaked ? "a-select--naked" : ""}`}>
      {label && <label className="a-select__label">{label}</label>}
      <Component
        className="a-select__inner"
        classNamePrefix="a-select"
        menuPlacement="auto"
        components={{
          LoadingIndicator: () => null,
          IndicatorSeparator: null,
          LoadingMessage: () => (
            <Loader size={LoaderSizes.Small} className="a-select__loader" />
          ),
          DropdownIndicator: () => <IconComponent name={iconName} />,
        }}
        {...otherProps}
      />
    </div>
  );
}

export default Select;
