export enum IconName {
  RightArrow = "rightArrow",
  Clock = "clock",
  Close = "close",
  Calendar = "calendar",
  DownArrow = "downArrow",
  LeftArrow = "leftArrow",
  Star = "start",
  Question = "question",
  DownChevron = "downChevron"
}

type TIconComponentProps = {
  name: IconName;
};

function IconComponent({ name }: TIconComponentProps) {
  switch (name) {
    case IconName.RightArrow:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.35938 10.8125L9.64062 8.00141L6.35938 5.1875"
            stroke="#242425"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case IconName.Clock:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <path
            d="M1.69531 2.64844C2.84375 1.5 4.22005 0.925781 5.82422 0.925781C7.44661 0.925781 8.82292 1.5 9.95312 2.64844C11.1016 3.77865 11.6758 5.14583 11.6758 6.75C11.6758 8.35417 11.1016 9.73047 9.95312 10.8789C8.82292 12.0091 7.44661 12.5742 5.82422 12.5742C4.22005 12.5742 2.84375 12.0091 1.69531 10.8789C0.565104 9.73047 0 8.35417 0 6.75C0 5.14583 0.565104 3.77865 1.69531 2.64844ZM2.54297 10.0586C3.45443 10.9701 4.54818 11.4258 5.82422 11.4258C7.11849 11.4258 8.22135 10.9701 9.13281 10.0586C10.0443 9.14714 10.5 8.04427 10.5 6.75C10.5 5.45573 10.0443 4.35286 9.13281 3.44141C8.22135 2.52995 7.11849 2.07422 5.82422 2.07422C4.54818 2.07422 3.45443 2.52995 2.54297 3.44141C1.63151 4.35286 1.17578 5.45573 1.17578 6.75C1.17578 8.04427 1.63151 9.14714 2.54297 10.0586ZM6.125 3.82422V6.88672L8.75 8.44531L8.3125 9.18359L5.25 7.32422V3.82422H6.125Z"
            fill="#353535"
          />
        </svg>
      );

    case IconName.Close:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="#242425"
          stroke="#242425"
        >
          <line x1="1.83793" y1="1.13082" x2="8.86918" y2="8.16207" />
          <line
            y1="-0.5"
            x2="9.94369"
            y2="-0.5"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 8.51562 1.48438)"
          />
        </svg>
      );

    case IconName.Calendar:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="#353535"
        >
          <path d="M11.4473 2.25C11.8457 2.25 12.1777 2.39388 12.4434 2.68164C12.7311 2.94727 12.875 3.2793 12.875 3.67773V13.5723C12.875 13.9707 12.7311 14.3138 12.4434 14.6016C12.1777 14.8672 11.8457 15 11.4473 15H1.55273C1.1543 15 0.811198 14.8672 0.523438 14.6016C0.257812 14.3138 0.125 13.9707 0.125 13.5723V3.67773C0.125 3.2793 0.257812 2.94727 0.523438 2.68164C0.811198 2.39388 1.1543 2.25 1.55273 2.25H2.25V0.822266H3.67773V2.25H9.32227V0.822266H10.75V2.25H11.4473ZM11.4473 13.5723V5.80273H1.55273V13.5723H11.4473ZM2.94727 7.19727H6.5V10.75H2.94727V7.19727Z" />
        </svg>
      );

    case IconName.DownArrow:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#5A5A5A"
        >
          <path
            d="M6.25 8.125L9.99812 12.5L13.75 8.125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case IconName.LeftArrow:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="9"
          viewBox="0 0 7 9"
          fill="#353535"
        >
          <path d="M6.12305 1.37109L2.86914 4.625L6.12305 7.87891L5.12695 8.875L0.876953 4.625L5.12695 0.375L6.12305 1.37109Z" />
        </svg>
      );

    case IconName.Star:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="#242425"
        >
          <path d="M5.82422 9.8125L2.21484 12L3.17188 7.89844L0 5.13672L4.18359 4.78125L5.82422 0.925781L7.46484 4.78125L11.6758 5.13672L8.47656 7.89844L9.43359 12L5.82422 9.8125Z" />
        </svg>
      );

    case IconName.Question:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black">
          <path d="M11.4531 10.0804L16 14.6273L14.6273 16L10.0804 11.4531V10.7239L9.82306 10.4665C8.70778 11.4102 7.42091 11.882 5.96247 11.882C4.30384 11.882 2.88829 11.3101 1.71582 10.1662C0.571939 8.99374 0 7.59249 0 5.96247C0 4.30384 0.571939 2.90259 1.71582 1.75871C2.88829 0.586238 4.28954 0 5.91957 0C7.5782 0 8.97945 0.586238 10.1233 1.75871C11.2958 2.90259 11.882 4.30384 11.882 5.96247C11.882 7.42091 11.4102 8.70778 10.4665 9.82306L10.7239 10.0804H11.4531ZM3.04558 8.87936C3.84629 9.68007 4.81859 10.0804 5.96247 10.0804C7.10635 10.0804 8.07864 9.68007 8.87936 8.87936C9.68007 8.07864 10.0804 7.10635 10.0804 5.96247C10.0804 4.81859 9.68007 3.84629 8.87936 3.04558C8.07864 2.24486 7.10635 1.8445 5.96247 1.8445C4.81859 1.8445 3.84629 2.24486 3.04558 3.04558C2.24486 3.84629 1.8445 4.81859 1.8445 5.96247C1.8445 7.10635 2.24486 8.07864 3.04558 8.87936Z" fill="black"/>
        </svg>
      );
    
    case IconName.DownChevron:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M6.25 8.125L9.99812 12.5L13.75 8.125" stroke="#242425" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      );

    default:
      return <></>;
  }
}

export default IconComponent;
